import styled from 'styled-components';

export const BackgroundContainer = styled.div`
  .background {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
    .gradient-layer {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 20%);
    }
  }

  img {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 100%;
    object-fit: cover;
  }
`;

export const styles = {
  title: {
    fontStyle: 'normal',
    fontSize: ['35px', '50px', '50px'],
    fontWeight: 'bold',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['20px', '40px', '40px', '40px', '40px'],
    lineHeight: '1.25',
    textAlign: 'center',
  },
  description: {
    fontSize: ['20px', '25px'],
    color: '#fff',
    lineHeight: '1.3',
    mb: '0',
    mt: 60,
    ml: 'auto',
    mr: 'auto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textAlign: 'center',
    maxWidth: 600,
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
    mt: '30px',
    width: '100%',
  },
};
