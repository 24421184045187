import styled from 'styled-components';
import Button from '..';
import { generalButtonStyles } from '../variants';

export const PrimaryButtonStyle = styled(Button)`
  background: linear-gradient(90deg, rgb(127, 1, 160) 0%, rgb(1, 73, 182) 92.91%);
  &:hover {
    color: white;
  }
`;

export const styles = {
  button: {
    ...generalButtonStyles,
    colors: 'secondaryWithBg',
    color: 'white',
  },
};
