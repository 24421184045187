import styled from 'styled-components';

export const BannerWrapper = styled.section`
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;

  .contents {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: relative;
  }
`;
