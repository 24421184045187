import React from 'react';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';

import SolutionSectionWrapper, { BenefitImg, styles } from './solution.style';
import BuiltForConsistencyImg from 'common/assets/image/syncStage/syncstage-built-for-consistency.png';
import BuiltForSecurityImg from 'common/assets/image/syncStage/syncstage-built-for-security.png';
import BuiltForScaleImg from 'common/assets/image/syncStage/syncstage-built-for-scale.png';
import BuiltToBeCrossPlatformImg from 'common/assets/image/syncStage/syncstage-built-to-be-cross-platform.png';

const SolutionSection = () => {
  const { title, textStyle, titleStyle, textStyle1 } = styles;
  return (
    <SolutionSectionWrapper id="info_section">
      <Box>
        <Heading {...title} content="Our Solution" />
        <Text {...textStyle1} content="SYNCSTAGE, THE REAL-TIME AUDIO INFRASTRUCTURE FOR THE METAVERSE" />
      </Box>
      <div className="first-bg"></div>
      <div className="benefit">
        <BenefitImg src={BuiltForConsistencyImg} alt="consistency" loading="lazy" />
        <Box className="text-box">
          <Heading {...titleStyle}>
            <React.Fragment>
              BUILT FOR <span className="emphasize">CONSISTENCY</span>
            </React.Fragment>
          </Heading>
          <Text
            {...textStyle}
            content="Design from the ground up to deliver consistent ultra-low latency for your customers, minimizing jitter (spiking) and maximizing speed, we deliver the smoothest communication experience between your performers, using 5G technology. "
          />
        </Box>
      </div>
      <div className="benefit">
        <BenefitImg src={BuiltForSecurityImg} alt="security" loading="lazy" />
        <Box className="text-box">
          <Heading {...titleStyle}>
            <React.Fragment>
              BUILT FOR <span className="emphasize">SECURITY</span>
              <br />
              <span className="subtitle">(99.9% UPTIME + 24 x 7 SUPPORT)</span>
            </React.Fragment>
          </Heading>
          <Text
            {...textStyle}
            content="Designed for the demands of telecom networks, we have built SyncStage to be enterprise ready. "
          />
        </Box>
      </div>
      <div className="benefit">
        <BenefitImg src={BuiltForScaleImg} alt="scale" loading="lazy" />
        <Box className="text-box">
          <Heading {...titleStyle}>
            <React.Fragment>
              BUILT FOR <span className="emphasize">SCALE</span>
            </React.Fragment>
          </Heading>
          <Text {...textStyle} content="Unleash the power of real-time communication between your avatars, at scale." />
        </Box>
      </div>
      <div className="benefit">
        <BenefitImg src={BuiltToBeCrossPlatformImg} alt="cross-platform" loading="lazy" />
        <Box className="text-box">
          <Heading {...titleStyle}>
            <React.Fragment>
              BUILT TO BE
              <br />
              <span className="emphasize">CROSS-PLATFORM</span>
            </React.Fragment>
          </Heading>
          <Text
            {...textStyle}
            content="SyncStage’s SDK integrates directly into Web, iOS and Android, to integrate into the virtual worlds you are creating."
          />
        </Box>
      </div>
    </SolutionSectionWrapper>
  );
};

export default SolutionSection;
