import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import Container from 'common/components/UI/Container';
import { PricingWrapper, Table, TableRow, TableData, TableHead, ProductName } from './pricing.style';
import Dots from 'common/assets/image/syncStage/dots.png';
import Image from 'common/components/Image';

const PricingSection = ({ row, title, buttonWrapper, fillButton }) => {
  return (
    <PricingWrapper id="pricing_section">
      <Image src={Dots} alt="Background dots" className="dots" />
      <Container className="pricing-wrapper">
        <Box {...row}>
          <Box>
            <Heading {...title} content="Pricing" />
            <Box className="pricing-box">
              <p className="subtitle">B2B Wholesale Subscription Model ($US)</p>
              <div className="scrolling-wrapper">
                <Table>
                  <TableRow style={{ border: 'None' }}>
                    <TableHead></TableHead>
                    <TableHead></TableHead>
                    <TableHead colSpan={3} className="backdrop text-center">
                      <ProductName>
                        Sync<span>Stage</span>
                      </ProductName>
                    </TableHead>
                  </TableRow>
                  <TableRow style={{ border: 'None' }}>
                    <TableHead></TableHead>
                    <TableHead>
                      <ProductName>
                        Chat<span>Stage</span>
                      </ProductName>
                    </TableHead>
                    <TableHead className="backdrop">
                      <ProductName>
                        Local<span>Stage</span>
                      </ProductName>
                    </TableHead>
                    <TableHead className="backdrop">
                      <ProductName>
                        Main<span>Stage</span>
                      </ProductName>
                    </TableHead>
                    <TableHead className="backdrop">
                      <ProductName>
                        Arena<span>Stage</span>
                      </ProductName>
                    </TableHead>
                  </TableRow>
                  <TableRow className="selected-row">
                    <TableData>Per Minute per User Wholesale Price</TableData>
                    <TableData className="blue-number">$0.00375</TableData>
                    <TableData className="blue-number backdrop">$0.0100</TableData>
                    <TableData className="blue-number backdrop">$0.0125</TableData>
                    <TableData className="blue-number backdrop">$0.0150</TableData>
                  </TableRow>
                  <TableRow>
                    <TableData>Product Features</TableData>
                    <TableData className="blue-number">Voice Chat</TableData>
                    <TableData className="blue-number backdrop">Scheduled Sessions</TableData>
                    <TableData className="blue-number backdrop">On-Demand & Scheduled Sessions</TableData>
                    <TableData className="blue-number backdrop">Enterprise Deployments</TableData>
                  </TableRow>
                  <TableRow>
                    <TableData>Speed / Latency vs. Competition</TableData>
                    <TableData className="blue-number">2x Comp</TableData>
                    <TableData className="blue-number backdrop">5x Comp</TableData>
                    <TableData className="blue-number backdrop">5x Comp</TableData>
                    <TableData className="blue-number backdrop">5x Comp</TableData>
                  </TableRow>
                  <TableRow>
                    <TableData>Quality Score vs. Competition</TableData>
                    <TableData className="blue-number">Better</TableData>
                    <TableData className="blue-number backdrop">2x Comp</TableData>
                    <TableData className="blue-number backdrop">2x Comp</TableData>
                    <TableData className="blue-number backdrop">2x Comp</TableData>
                  </TableRow>
                </Table>
              </div>
              <Box {...buttonWrapper}>
                <a href={process.env.REQUEST_DEMO_URL} target="_blank" rel="noreferrer">
                  <Button
                    {...fillButton}
                    style={{ background: 'linear-gradient(90deg, #7F01A0 0%, #0149B6 92.91%)', color: 'white' }}
                    title="Sign Up"
                  />
                </a>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </PricingWrapper>
  );
};

PricingSection.propTypes = {
  row: PropTypes.object,
  title: PropTypes.object,
  buttonWrapper: PropTypes.object,
  fillButton: PropTypes.object,
};

PricingSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: ['26px', '34px', '42px', '44px', '48px'],
    fontWeight: 'bold',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px', '25px', '25px', '25px'],
    lineHeight: '1',
    textAlign: 'center',
  },
  fillButton: {
    type: 'button',
    fontSize: ['13px', '14px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    colors: 'secondaryWithBg',
    minWidth: ['auto', '150px'],
    height: ['40px', '46px'],
    minHeight: 'auto',
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
    mt: '35px',
  },
};

export default PricingSection;
