import styled from 'styled-components';
import BG from 'common/assets/image/syncStage/dots.png';

const PartnerSectionWrapper = styled.section`
  padding: 5rem 0;
  background-image: url(${BG});
  background-repeat: no-repeat;
  background-position: 0px 100px;

  @media (max-width: 900px) {
    background-image: none;
  }
`;

export default PartnerSectionWrapper;

export const GridWrapper = styled.div`
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    gap: 50px 30px;
  }
  @media only screen and (max-width: 480px) {
    gap: 50px 30px;
  }
`;

export const styles = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentWrapper: {
    width: ['100%', '100%', '80%', '55%', '50%'],
    mb: '40px',
  },
  title: {
    fontSize: ['26px', '34px', '42px', '44px', '48px'],
    fontWeight: 'bold',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px', '25px', '25px', '25px'],
    lineHeight: '1',
    textAlign: 'center',
    fontStyle: 'normal',
  },
  textStyle: {
    color: '#fff',
    maxWidth: 980,
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: ['20px', '25px'],
    lineHeight: '150%',
    mt: '30px',
  },
  textStyle1: {
    color: '#36DBFD',
    maxWidth: 980,
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '25px',
    lineHeight: '150%',
    pt: '2rem',
  },
};
