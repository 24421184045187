import React, { useEffect, useState } from 'react';
import { ImgContainer } from './style';

const VIDEO_EXTENSIONS = ['mp4'];

/**
 *
 * @param {{
 *  src: string;
 *  loadingSrc: string;
 *  alt?: string;
 * }} param0
 * @returns {JSX.Element}
 */
export default function LazyMedia({ src, loadingSrc, alt }) {
  const [loaded, setLoaded] = useState(false);
  const extension = src.split('.').pop();
  const isVideo = VIDEO_EXTENSIONS.includes(extension.toLowerCase());
  const elementRef = React.useRef(null);

  useEffect(() => {
    if (elementRef.current.complete) {
      setLoaded(true);
    }
    if (isVideo) {
      elementRef.current.oncanplay = () => {
        setLoaded(true);
      };
    } else {
      elementRef.current.onload = () => {
        setLoaded(true);
      };
    }
  }, [isVideo, src]);
  return (
    <ImgContainer isLoaded={loaded} preload="none" loadingBackground={loadingSrc}>
      {isVideo ? (
        <video autoPlay muted controls={false} loop ref={elementRef} type={`video/${extension}`}>
          <source src={src} />
        </video>
      ) : (
        <img src={src} loading="lazy" alt={alt} ref={elementRef} />
      )}
    </ImgContainer>
  );
}
