import React from 'react';
import BannerSection from 'containers/SyncStage/BannerMetaverse';
import PricingSection from 'containers/SyncStage/Pricing';
import LatencySection from '../containers/SyncStage/Latency';
import SolutionSection from '../containers/SyncStage/Solution';
import Layout from '../components/layout';

const Metaverse = () => {
  return (
    <Layout>
      <BannerSection />
      <LatencySection />
      <SolutionSection />
      <PricingSection />
    </Layout>
  );
};
export default Metaverse;
