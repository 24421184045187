import React from 'react';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import { BackgroundContainer, styles } from './banner.style';
import Image from 'common/components/Image';
import Logo from 'common/assets/image/syncStage/sync-circle-logo.png';
import StaticBanner from 'components/Banner/StaticBanner/StaticBanner';
import BannerCenterText from 'components/Banner/BannerCenterText/BannerCenterText';
import PrimaryButton from 'common/components/Button/PrimaryButton/PrimaryButton';

import BannerBG from 'common/assets/image/syncStage/banner-bg-metaverse.png';
import BannerBGSmall from 'common/assets/image/syncStage/banner-bg-metaverse-small.webp';
import LazyMedia from 'common/components/LazyMedia/LazyMedia';

const BannerSection = () => {
  const { title, description, buttonWrapper } = styles;
  return (
    <StaticBanner
      bg={
        <BackgroundContainer>
          <div className="background">
            <LazyMedia src={BannerBG} loadingSrc={BannerBGSmall} alt="SyncStage Metaverse Banner" />
            <div className="gradient-layer"></div>
          </div>
        </BackgroundContainer>
      }
    >
      <BannerCenterText>
        <Heading {...title}>
          <React.Fragment>
            <span className="emphasize">SYNCSTAGE: </span>THE REAL-TIME AUDIO INFRASTRUCTURE FOR THE METAVERSE
          </React.Fragment>
        </Heading>
        <Image style={{ width: '5rem' }} src={Logo}></Image>
        <Text {...description}>
          <React.Fragment>Deliver consistent low latency between a billion avatars using SyncStage</React.Fragment>
        </Text>
        <Box {...buttonWrapper}>
          <a
            href={process.env.REQUEST_DEMO_URL}
            style={{ width: '100%', textAlign: 'center' }}
            target="_blank"
            rel="noreferrer"
          >
            <PrimaryButton title="SIGN UP" />
          </a>
        </Box>
      </BannerCenterText>
    </StaticBanner>
  );
};

export default BannerSection;
