import React from 'react';
import { BannerWrapper } from './style';

function StaticBanner({ children, bg }) {
  return (
    <BannerWrapper id="banner_section">
      {bg}
      <div className="contents">{children}</div>
    </BannerWrapper>
  );
}
export default StaticBanner;
