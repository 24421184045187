import React from 'react';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';

import LatencySectionWrapper, { styles } from './section.style';

const LatencySection = () => {
  const { row, title, contentWrapper, textStyle, textStyle1 } = styles;
  return (
    <LatencySectionWrapper>
      <Container>
        <Box {...row}>
          <Box {...contentWrapper}>
            <Heading {...title} content="Latency is as Critical as Air for the Metaverse" />
          </Box>
        </Box>
        <Box className="text" {...row}>
          <Box>
            <Text
              {...textStyle}
              content="In the discussions of the future of real-time communications such as the Metaverse - where people effectively live, work and socialise in a virtual space -  bandwidth is one thing. But if it takes the avatar we’re engaging with several seconds to respond then the life we want in the metaverse is suddenly annoying and inhospitable."
            />
            <Text
              {...textStyle}
              content="If we are already frustrated to have delay when streaming live sports or gaming online, this will only be amplified trying to engage with others in a virtual world."
            />
            <Text {...textStyle1} content="Latency, in short, becomes as critical as air for the metaverse." />
          </Box>
        </Box>
      </Container>
    </LatencySectionWrapper>
  );
};

export default LatencySection;
