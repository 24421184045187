import styled from 'styled-components';
import FirstBg from 'common/assets/image/syncStage/pattern-1@2x.png';

export const BenefitImg = styled.img`
  margin-left: 52px;
  width: 500px;
  object-fit: contain;

  @media (max-width: 767px) {
    margin-left: 0;
    width: 100%;
  }
`;

const SolutionSectionWrapper = styled.section`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
  padding-bottom: 1rem;
  padding-inline: 1rem;

  .benefit {
    padding-bottom: 4rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .text-box {
    margin-top: 1rem;
    margin-left: 52px;
    margin-right: 52px;
    min-width: 300px;
    max-width: 500px;
  }

  .first-bg {
    background-image: url(${FirstBg});
    background-position: top right;
    background-size: contain;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 800px;
  }
`;

export default SolutionSectionWrapper;

export const styles = {
  title: {
    fontSize: ['34px', '42px', '44px', '48px'],
    fontWeight: 'bold',
    color: '#fff',
    letterSpacing: '-0.025em',
    pt: ['60px', '80px', '100px', '150px', '150px'],
    pb: ['30px', '40px', '50px', '75px', '75px'],
    lineHeight: '1',
    textAlign: 'center',
    fontStyle: 'normal',
  },
  textStyle1: {
    color: '#36DBFD',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '22px',
    lineHeight: '150%',
    mb: '4rem',
  },
  textStyle: {
    color: '#fff',
    maxWidth: 980,
    textAlign: ['center', 'left'],
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '22px',
    lineHeight: '150%',
  },
  titleStyle: {
    fontSize: ['26px', '36px', '40px'],
    fontWeight: 'bold',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['10px', '20px', '30px', '40px', '40px'],
    lineHeight: '1.1',
    textAlign: ['center', 'left'],
  },
};
