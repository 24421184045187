import styled from 'styled-components';
import PricingBg from 'common/assets/image/syncStage/pricing-bg.png';

export const Table = styled.table`
  color: #ffffff;
  border-collapse: collapse;

  .backdrop {
    background: rgba(217, 217, 217, 0.15);
  }

  .text-center {
    text-align: center;
  }
`;

export const TableHead = styled.th`
  font-size: 18px;
  text-align: left;
  padding: 12px;

  @media (max-width: 767px) {
    font-size: 14px;
  }
`;

export const TableRow = styled.tr`
  border-bottom: 1pt solid #5d729e;
`;

export const ProductName = styled.span`
  text-transform: uppercase;
  font-weight: 900;
  font-size: 28px;

  span {
    font-weight: 100;
  }
`;

export const TableData = styled.td`
  padding-left: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 16px;

  @media (max-width: 767px) {
    padding-left: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 12px;
  }
`;

export const PricingWrapper = styled.section`
  background-image: url(${PricingBg});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  z-index: 0;

  .logo-img {
    height: 22px;

    @media (max-width: 767px) {
      height: 16px;
    }
  }
  .subtitle {
    color: white;
    font-size: 22px;
    text-align: center;
    margin-bottom: 32px;
  }

  .selected-row {
    background: rgba(217, 217, 217, 0.1);
    color: #ffffff;
    border: 0;
    border-radius: 15px;
    border-bottom: 1pt solid #5d729e;
    font-weight: 700;
  }

  .blue-number {
    color: #36dbfd !important;
  }

  .dots {
    top: 90px;
    right: 20px;
    position: absolute;

    @media (max-width: 990px) {
      top: 60px;
      right: 10px;
    }
    @media (max-width: 767px) {
      top: 0;
      right: 0;
    }
    @media (max-width: 575px) {
      display: none;
    }
  }

  .pricing-wrapper {
    padding: 150px 0 200px 0;

    @media (max-width: 990px) {
      padding: 100px 0;
    }
    @media (max-width: 767px) {
      padding: 80px 0;
    }
    @media (max-width: 575px) {
      padding: 60px 0;
    }
  }

  .pricing-box {
    border: 3px solid;
    border-image-slice: 1;
    border-image-source: radial-gradient(
      89.85% 105.84% at 44.89% 3.77%,
      #00fff0 0%,
      #00c2ff 17.71%,
      #004cdf 40.63%,
      #321497 58.85%,
      #9800cd 81.25%,
      #cd00a0 100%
    );
    padding: 40px 80px;
    margin: 70px 0 0 0;

    @media (max-width: 990px) {
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      max-width: 90vw;
      padding: 40px 70px;
      margin: 70px 0 0 0;
    }
    @media (max-width: 767px) {
      padding: 30px 60px;
      margin: 60px 0 0 0;
    }
    @media (max-width: 575px) {
      padding: 20px 40px;
      margin: 50px 0 0 0;
    }
  }
`;
