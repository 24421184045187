import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const ImgContainer = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${(props) => props.loadingBackground});
  animation: pulse 2.5s infinite;
  width: 100%;
  height: 100%;

  & img,
  & video {
    opacity: 0;
    transition: opacity 250ms ease-in-out;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${(props) =>
    props.isLoaded &&
    css`
      animation: none;
      background-image: none;

      & img,
      & video {
        opacity: 1;
      }
    `}

  @keyframes pulse {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0.2;
    }
  }
`;
